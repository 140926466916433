import { defineStore, acceptHMRUpdate } from 'pinia';
import { computed, onBeforeMount } from 'vue';
import { useProductsStore } from './productsStore';
import { useNotificationStore } from './notificationStore';
import handleFrontendError from '../utils/handleFrontendError';
import type {
	CartItem,
	Customer,
	FormattedCartData,
	PricingTierRule,
	PricingTierWithRules,
	Product,
	ProductInventory,
} from '~/types/app.types';
import type { PricedCartData } from '~/types/api/dutchie.types';

export const useCartStore = defineStore('Cart', () => {
	const MI_MAX_GRAMS = 70;
	const MI_MAX_CONCENTRATE_GRAMS = 15;

	// Location will become dynamic once we have more than one shop location
	const location =
		useRuntimeConfig().public.IS_STAGING || process.env.NODE_ENV === 'development'
			? 'sandbox'
			: 'monroe';

	const ProductsStore = useProductsStore();
	const NotificationStore = useNotificationStore();

	const loading = ref(true);
	const items = ref<CartItem[]>([]);

	const cartOpen = ref(false);
	const merchCrossSellOpen = ref(false);

	const customerInfo = ref<Customer>({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		birthday: '',
	});

	/** The total count of items in the cart. */
	const itemsCount = computed(() => items.value.reduce((sum, item) => sum + item.quantity, 0));

	const cartDataFromDutchiePOS = ref<PricedCartData | null>(null);

	/** Returns the total of the cart post-discounts and pre-taxes */
	const total = computed(() => {
		return subTotal.value - totalDiscount.value.totalDiscountDollarAmount;
	});

	const pricingTierDefinitions = computed(() => ProductsStore.pricingTiers);

	/**
	 * Returns an array of unique categories in the current shopping cart.
	 * @returns - Array of unique product categories
	 */
	const categoriesInCart = computed(() => [
		...new Set(items.value.flatMap((item) => item.categories)),
	]);

	/**
	 * Computes a list of cross-sell products based on the categories present in the cart.
	 * Excludes items already in cart.
	 * Returns up to three relevant products.
	 * @returns - A random selection of products that match the categories of items
	 *            already in the users cart.
	 */
	const crossSellProducts = computed(() => {
		if (categoriesInCart.value.length) {
			const relevantProducts = ProductsStore.products.filter((product: Product) => {
				// 1. Exclude products already in the cart
				const isInCart = items.value.some((item) => item.id === product.dutchieId);
				if (isInCart) return false; // Skip this product if it's in the cart

				// 2. Match categories in cart
				const matchesCategory = product.productMetadata?.productCategories?.some(
					(category) => categoriesInCart.value.includes(category.name),
				);

				// 3. Exclude 'Clothing'
				const hasClothingCategory = product.productMetadata?.productCategories?.some(
					(category) => category.name === 'Clothing',
				);

				return matchesCategory && !hasClothingCategory; // Only include if it matches a category and does not have 'Clothing'
			});

			// Shuffle relevant products array and return the first 3 elements
			// eslint-disable-next-line security-node/detect-insecure-randomness
			return relevantProducts.sort(() => 0.5 - Math.random()).slice(0, 3);
		}

		return [];
	});

	const merchCrossSellProducts = computed<Product[]>(() => {
		const relevantProducts = ProductsStore.products.filter((product: Product) => {
			// Check if product has category "Merch"
			const hasMerchCategory = product.productMetadata?.productCategories?.some(
				(category) => category.name === 'Merch',
			);

			// Check if product does NOT have category "Clothing"
			const doesNotHaveClothingCategory = !product.productMetadata?.productCategories?.some(
				(category) => category.name === 'Clothing',
			);

			// Return true if product has "Merch" category and doesn't have "Clothing" category
			return hasMerchCategory && doesNotHaveClothingCategory;
		});

		// Shuffle relevant products array and return the first 3 elements
		if (relevantProducts.length >= 3) {
			// eslint-disable-next-line security-node/detect-insecure-randomness
			return relevantProducts.sort(() => 0.5 - Math.random()).slice(0, 3);
		}

		// If there are not enough merch products in stock show a random assortment of all products as a fallback.
		// eslint-disable-next-line security-node/detect-insecure-randomness
		return ProductsStore.products.sort(() => 0.5 - Math.random()).slice(0, 3);
	});

	/**
	 * Filters out any items from the cart that are not present in the current products store.
	 * This is useful for removing items that are no longer available when populating a cart
	 * from local storage.
	 * @param items - The cart item objects.
	 */
	function checkCartItemsAvailibility(items: CartItem[]) {
		return items.filter((item) => {
			if (item.isGroupedProduct) {
				// Check grouped products
				return ProductsStore.products.some(
					(product: Product) =>
						product.groupedProductData &&
						product.groupedProductData.some(
							(groupedItem) => groupedItem.id === item.id,
						),
				);
			} else {
				// Check non-grouped products
				return ProductsStore.products.some(
					(product: Product) => product.dutchieId === item.id,
				);
			}
		});
	}

	/** Populates the cart from the items stored in local storage. */
	async function populateCartFromLocalStorage() {
		const cartInLocalStorage = localStorage.getItem('cart');
		if (cartInLocalStorage) {
			await waitForProducts();
			items.value = checkCartItemsAvailibility(JSON.parse(cartInLocalStorage));
		}
		loading.value = false;
	}

	/** Populates the customerInfo from the items stored in local storage. */
	function populateCustomerFromLocalStorage() {
		const customerInLocalStorage = localStorage.getItem('customer');
		if (customerInLocalStorage) {
			customerInfo.value = JSON.parse(customerInLocalStorage);
		}
	}

	// Call the 'populateCartFromLocalStorage' function before the Vue.js component is mounted
	onBeforeMount(() => {
		// Ensure this code runs only in the client-side
		if (import.meta.client) {
			populateCartFromLocalStorage();
			populateCustomerFromLocalStorage();
		}
	});

	/**
	 * Watches for changes to the 'items' reactive property and saves those changes to local storage.
	 * The { deep: true } option ensures that changes in nested properties trigger the watcher.
	 */
	watch(
		() => items,
		(state) => {
			localStorage.setItem('cart', JSON.stringify(state.value));
		},
		{ deep: true },
	);

	/**
	 * Watches for changes to the 'customerInfo' reactive property and saves those changes to local storage.
	 * The { deep: true } option ensures that changes in nested properties trigger the watcher.
	 */
	watch(
		() => customerInfo,
		(state) => {
			localStorage.setItem('customer', JSON.stringify(state.value));
		},
		{ deep: true },
	);

	/**
	 * A computed property that represents the subtotal of all items.
	 *
	 * For items that apply tiered pricing, the dropdown selection shows discounted prices
	 * for all tiers above the base tier. For instance, 1/4oz will display as $50, which is a discounted price.
	 * To correctly compute and display the subtotal and total, this property uses the non-discounted
	 * price where applicable. In the above example, 1/4oz would be represented as $60, not the discounted $50.
	 */
	const subTotal = computed(() => {
		if (items.value.length) {
			return items.value.reduce((total, item) => {
				if (item.priceNonDiscounted) {
					return total + item.quantity * item.priceNonDiscounted;
				} else {
					return total + item.quantity * (item.price ?? 0);
				}
			}, 0);
		} else {
			return 0;
		}
	});

	/**
	 * Returns the total number of grams in the cart
	 *
	 * Number of grams - includes both flower and concentrate products.
	 * If the cart has no cannabis items, returns 0.
	 */
	const gramsInCart = computed(() => {
		if (items.value.length) {
			const gramsInCart = items.value.reduce(
				(grams, item) => grams + (item.grams ?? 0) * item.quantity,
				0,
			);

			return gramsInCart;
		} else {
			return 0;
		}
	});

	/**
	 * Calculates the percentage of grams in the cart compared to the maximum limit.
	 *
	 * Percentage of the total grams in the cart relative to the maximum limit.
	 * If the cart is empty, returns 0.
	 */
	const gramsToCartLimitPercentage = computed(() => {
		if (gramsInCart.value) {
			// return parseFloat((gramsInCart.value / MI_MAX_GRAMS) * 100);
			return (gramsInCart.value / MI_MAX_GRAMS) * 100;
		} else {
			return 0;
		}
	});

	/**
	 * Returns the total number of concentrate grams in the cart
	 * If the cart has no concentrate items, returns 0.
	 */
	const concentrateGramsInCart = computed(() => {
		if (items.value.length) {
			const concentrateGramsInCart = items.value.reduce((grams, item) => {
				if (item.isConcentrate) {
					return grams + (item.grams ?? 0) * item.quantity;
				}
				return grams;
			}, 0);

			return concentrateGramsInCart;
		} else {
			return 0;
		}
	});

	/**
	 * Compute total pricing tiers discount(s) within the cart.
	 *
	 * This function first filters the items that have a pricing tier. It then groups these items by their pricing tier.
	 * For each group of items (sharing the same pricing tier), it calculates the total grams of items.
	 * The function also determines the applicable pricing tier for the total grams and calculates the discounted and regular prices.
	 * Finally, it calculates the total discount dollar amount by subtracting the discounted price from the regular price for each group.
	 *
	 * An object that maps each price tier to its respective discounted price, regular price, and total grams.
	 * An additional property, `totalDiscountDollarAmount`, is also included in the returned object to denote the total discount dollar amount in the cart.
	 */
	const totalDiscount = computed(() => {
		const itemsWithPriceTiers = items.value.filter((item) => item.pricingTierId);

		// Group items by price tiers
		const groupedItemsWithPriceTiers: Record<string, CartItem[]> = {};

		for (const item of itemsWithPriceTiers) {
			if (item.pricingTierId) {
				if (!groupedItemsWithPriceTiers[item.pricingTierId]) {
					groupedItemsWithPriceTiers[item.pricingTierId] = [];
				}

				groupedItemsWithPriceTiers[item.pricingTierId].push(item);
			}
		}

		// Compute total grams per price tier
		const totalGramsPerTier: Record<string, number> = Object.entries(
			groupedItemsWithPriceTiers,
		).reduce((totalGrams, [tierId, items]) => {
			totalGrams[tierId] = items.reduce(
				(grams, item) => grams + item.quantity * (item.grams ?? 0),
				0,
			);
			return totalGrams;
		}, {});

		// Compute prices per grouped items
		const pricesOfGroupedItems: Record<
			number,
			{
				discountedPrice: number;
				regularPrice: number;
				totalGrams: number;
			}
		> = {};

		for (const [priceTierId, sumOfGrams] of Object.entries(totalGramsPerTier)) {
			const priceTiers: PricingTierWithRules[] = pricingTierDefinitions.value.filter(
				(tier: PricingTierWithRules) => tier.id === Number(priceTierId),
			);

			if (priceTiers.length) {
				const priceTier = priceTiers[0];

				if (priceTier.pricingTierRules && priceTier.pricingTierRules.length) {
					const baseTier = priceTier.pricingTierRules[0];
					const applicableTier = priceTier.pricingTierRules.filter(
						(tier: PricingTierRule) =>
							tier.startWeightGrams <= Number(sumOfGrams) &&
							tier.endWeightGrams > Number(sumOfGrams),
					);

					if (applicableTier.length) {
						pricesOfGroupedItems[priceTierId] = {
							discountedPrice: parseFloat(
								(applicableTier[0].pricePerGram * sumOfGrams).toFixed(2),
							),
							regularPrice: parseFloat(
								(baseTier.pricePerGram * sumOfGrams).toFixed(2),
							),
							totalGrams: sumOfGrams,
						};
					}
				}
			}
		}

		// Compute total discount dollar amount
		const totalDiscountDollarAmount = Object.values(pricesOfGroupedItems).reduce(
			(totalDiscount, prices) => {
				return totalDiscount + prices.regularPrice - prices.discountedPrice;
			},
			0,
		);

		return {
			groupedItemsWithPriceTiers,
			totalGramsPerTier,
			pricesOfGroupedItems,
			totalDiscountDollarAmount,
		};
	});

	/**
	 * Computed property function that formats cart data for Dutchie POS.
	 *
	 * Dutchie is designed to recognize products based on their smallest possible size. To accommodate
	 * products that come in larger sizes (products that utilize pricing tiers), we utilize the
	 * 'productQuantityMultiplier'. For instance, if a product is available in a 1/2oz size, the multiplier
	 * will be set to 4, signifying that it is equivalent to four 1/8oz portions.
	 * Returns an object containing the formatted cart data.
	 */
	const formattedCartDataForDutchiePOS = computed(() => {
		const summedQuantitesById: Record<number, number> = items.value.reduce((sum, item) => {
			if (!sum[item.id]) {
				sum[item.id] = 0;
			}

			if (item.productQuantityMultiplier) {
				sum[item.id] += item.quantity * item.productQuantityMultiplier;
			} else {
				sum[item.id] += item.quantity;
			}

			return sum;
		}, {});

		const formattedData: FormattedCartData[] = [];
		for (const [productId, quantity] of Object.entries(summedQuantitesById)) {
			formattedData.push({
				productId,
				quantity,
			});
		}

		return formattedData;
	});

	/**
	 * Determines if adding a certain amount of grams would exceed the maximum allowed grams (MI_MAX_GRAMS) in the cart.
	 *
	 * @param grams - The amount of grams to potentially add to the cart.
	 * @param quantity - [quantity=1] - The quantity of the product being addewd (defaults to 1).
	 * @returns - Returns true if adding the specified amount of grams would exceed the limit, false otherwise.
	 */
	function exceedsCartLimits(grams: number, quantity = 1) {
		return gramsInCart.value + grams * quantity > MI_MAX_GRAMS;
	}

	/**
	 * Determines if adding a certain amount of grams of concentrate would exceed the maximum allowed concentrate grams (MI_MAX_CONCENTRATE_GRAMS) in the cart.
	 *
	 * @param grams - The amount of concentrate grams to potentially add to the cart.
	 * @param quantity - [quantity=1] - The quantity of the product being addewd (defaults to 1).
	 * @returns - Returns true if adding the specified amount of concentrate grams would exceed the limit, false otherwise.
	 */
	function exceedsConcentrateCartLimits(grams: number, quantity = 1) {
		return concentrateGramsInCart.value + grams * quantity > MI_MAX_CONCENTRATE_GRAMS;
	}

	function quantityExceedsAvailableInventory(
		productId: number,
		quantity: number,
		isGroupedProduct = false,
	) {
		let storeSpecificInventory: ProductInventory | undefined;

		if (isGroupedProduct) {
			const groupedProducts = ProductsStore.products.flatMap(
				(product) => product.groupedProductData || [],
			);
			const product = groupedProducts.find(
				(groupedProduct) => groupedProduct.id === productId,
			);

			storeSpecificInventory = product?.inventory?.find(
				(inventory) => inventory.dutchieLocationId === ProductsStore.storeLocationId,
			);
		} else {
			const product = ProductsStore.products.find(
				(product) => product.dutchieId === productId,
			);

			storeSpecificInventory = product?.productInventory.find(
				(location) => location.dutchieLocationId === ProductsStore.storeLocationId,
			);
		}
		const availableInventory = storeSpecificInventory?.quantity;

		if (!availableInventory) {
			throw createError({ message: 'There is no available inventory for this product.' });
		}

		if (quantity <= availableInventory) {
			return false;
		}

		return true;
	}

	/**
	 * Adds an item to the shopping cart. If adding the item would cause the cart to exceed its gram or concentrate limits, it generates a warning and doesn't add the item.
	 *
	 * @param productId - The ID of the product to add.
	 * @param price - The price of the product.
	 * @param priceNonDiscounted - The original, non-discounted price of the product. Only applies to pricing tier products
	 * @param size - The size of the product.
	 * @param quantity - The quantity of the product to add.
	 * @param pricingTierId - The ID of the pricing tier for the product.
	 * @param productQuantityMultiplier - The multiplier to apply for quantity calculations.
	 * @param grams - [grams=0] - The weight in grams of the product (defaults to 0).
	 * @param isConcentrate - [isConcentrate=false] - Indicates if the product is a concentrate (defaults to false).
	 * @param isGroupedProduct - [isGroupedProduct=false] - Indicates if the product is a grouped product (defaults to false).
	 * @param categories - [categories=[]] - All categories assigned to the product. Will default to none.
	 */
	function addItemToCart(
		productId: number,
		price: number | null,
		priceNonDiscounted: number | null,
		size: string,
		quantity: number,
		pricingTierId: number | null,
		productQuantityMultiplier: number | null,
		grams: number | null,
		isConcentrate = false,
		isGroupedProduct = false,
		categories: string[] | null = [],
	) {
		try {
			if (grams) {
				if (exceedsCartLimits(grams, quantity)) {
					NotificationStore.createNotification({
						type: 'error',
						message: 'Adding this item will cause the cart to exceed its allowed limit',
					});
					console.warn('This will cause the cart to exceed its limit');
					return;
				}
			}

			const item = items.value.find((item) => item.id === productId && item.size === size);

			if (grams) {
				if (item && item.isConcentrate && exceedsConcentrateCartLimits(grams, quantity)) {
					NotificationStore.createNotification({
						type: 'error',
						message: 'This will cause the cart to exceed its concentrate limit',
					});
					console.warn('This will cause the cart to exceed its concentrate limit');
					return;
				}
			}

			if (quantityExceedsAvailableInventory(productId, quantity, isGroupedProduct)) {
				NotificationStore.createNotification({
					type: 'error',
					message:
						'There is not enough inventory in stock to meet your quantity. Please reduce your item count.',
				});
				return;
			}

			if (item) {
				item.quantity += quantity;
			} else {
				items.value.push({
					id: productId,
					price,
					priceNonDiscounted,
					size,
					quantity,
					pricingTierId,
					productQuantityMultiplier,
					grams,
					isConcentrate,
					isGroupedProduct,
					categories,
				});
			}
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	/** Increases the quantity of an item in the cart by 1 unit. */
	function incrementItemQuantity(productId: number, size: string, isGroupedProduct = false) {
		const item = items.value.find((item) => item.id === productId && item.size === size);

		if (!item) return;

		if (quantityExceedsAvailableInventory(productId, item.quantity + 1, isGroupedProduct)) {
			NotificationStore.createNotification({
				type: 'error',
				message: `Sorry, the quantity you've selected exceeds our current stock for this item.`,
			});
			return;
		}

		if (item.grams) {
			if (exceedsCartLimits(item.grams)) {
				NotificationStore.createNotification({
					type: 'error',
					message: 'Adding this item will cause the cart to exceed its allowed limit',
				});
				console.warn('This will cause the cart to exceed its limit');
				return;
			}

			if (item.isConcentrate && exceedsConcentrateCartLimits(item.grams)) {
				NotificationStore.createNotification({
					type: 'error',
					message: 'This will cause the cart to exceed its concentrate limit',
				});
				console.warn('This will cause the cart to exceed its concentrate limit');
				return;
			}
		}

		item.quantity++;
	}

	/**
	 * Decreases the quantity of an item in the cart by 1 unit.
	 * If the item has a quantity of 1 then this function removes the item from the cart.
	 */
	function decrementItemQuantity(productId: number, size: string) {
		const item = items.value.find((item) => item.id === productId && item.size === size);

		if (!item) return;

		if (item.quantity > 1) {
			item.quantity--;
		} else {
			items.value = items.value.filter(
				(item) => !(item.id === productId && item.size === size),
			);
		}
	}

	function removeItemFromCart(productId: number, size: string) {
		items.value = items.value.filter((item) => !(item.id === productId && item.size === size));
	}

	function clearCart() {
		items.value = [];
	}

	/**
	 * Sends a POST request to the '/api/dutchie/preorder/price-cart' endpoint to retrieve the price data for a shopping cart from the Dutchie POS system.
	 * The cart data that is sent in the POST request body is expected to have been previously formatted using the 'formattedCartDataForDutchiePOS' computed property.
	 */
	async function priceCartFromDutchiePOS() {
		if (!items.value.length) return;

		const response = await $fetch<PricedCartData>(
			`/api/dutchie/preorder/price-cart?location=${location}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: JSON.stringify({
					cart: formattedCartDataForDutchiePOS.value,
					customerTypeId: 2,
				}),
			},
		);

		cartDataFromDutchiePOS.value = response;
		return response;
	}

	return {
		addItemToCart,
		cartOpen,
		cartDataFromDutchiePOS,
		clearCart,
		concentrateGramsInCart,
		crossSellProducts,
		customerInfo,
		decrementItemQuantity,
		formattedCartDataForDutchiePOS,
		gramsInCart,
		gramsToCartLimitPercentage,
		incrementItemQuantity,
		items,
		itemsCount,
		loading,
		location,
		merchCrossSellProducts,
		merchCrossSellOpen,
		populateCartFromLocalStorage,
		priceCartFromDutchiePOS,
		removeItemFromCart,
		subTotal,
		total,
		totalDiscount,
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
